import { getRedirectUrl } from "../utils/index.js"

export default function Login({language}) {

  return (
    <div className="text-center grid place-items-center" style={{minHeight: 400}}>
      <h2 className="text-black">🇨🇷 {language.login.welcome} 🇨🇷</h2>

      <div className="w-full">
        
        <div className="m-4 mb-0 border rounded-lg border-blue-400 bg-blue-50 p-4">
          <p className="text-sm text-blue-700">
            {language.login.helperText}
          </p>
        </div>

        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-base font-semibold leading-6 text-gray-900">{language.login.newUserHeader}</h3>
          <div className="mt-2 text-sm text-gray-500">
            <p>
              {language.login.newUserText}
            </p>
          </div>
          <div className="mt-5">
            <a
              href={getRedirectUrl('registration')}
              type="button"
              className="inline-flex items-center rounded-md bg-red-700 px-3 py-2 text-sm font-semibold text-white dark:text-black shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
            >
              {language.login.newUserBtn}
            </a>
          </div>
        </div>
      </div>

      <div className="w-full">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-base font-semibold leading-6 text-gray-900">{language.login.returningUserHeader}</h3>
          <div className="mt-2 text-sm text-gray-500">
            <p>
             {language.login.returningUserText}
            </p>
          </div>
          <div className="mt-5 text-sm leading-6">
            <a href={getRedirectUrl('login')} className="font-semibold text-red-700 hover:text-red-500">
              {language.login.returningUserBtn}
              <span aria-hidden="true"> &rarr;</span>
            </a>
          </div>
        </div>
      </div>

    </div>
  )
}